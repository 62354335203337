import styled from "styled-components";

export const HeroWrapper = styled.div`
  position: relative;
  height: 80vh;
  min-height: 740px;
  z-index: 1;

  main {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 2rem 1rem;
      div {
        flex: 1;
        display: flex;
      }

      .text_section {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 2rem;
        p.above {
          font-size: 0.95rem;
        }
        h1 {
          font-weight: 700;
          span {
            color: ${(props) => props.theme.colors.secondary};
            &:after {
              content: " ";
              display: block;
            }
          }
        }
        p.below {
          max-width: 540px;
          margin: 0 2rem;
          font-size: 1rem;
        }
        .ctas {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;

          a:last-of-type {
            margin-bottom: 1rem;
          }
        }
      }
      .image_section {
        svg {
          width: 100%;
          max-width: 450px;
          height: auto;
          max-height: 265px;
        }
      }
    }
  }

  .background {
    position: absolute;
    bottom: 0px;
    left: -160px;
    right: -160px;

    background-image: linear-gradient(#effbff 0%, #fff 100%);
    /* background-image: linear-gradient(rgb(255, 255, 255) 0%, #00bbf0 100%); */
    z-index: -2;
    top: 0px;
    border-bottom-left-radius: 50% 25%;
    border-bottom-right-radius: 50% 25%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    main {
      .content {
        div {
        }

        .text_section {
          p.above {
            font-size: 1rem;
          }
          h1 {
            span {
              &:after {
              }
            }
          }
          p.below {
          }
          .ctas {
            flex-direction: row;

            a:last-of-type {
              margin-bottom: 0;
              margin-left: 1rem;
            }
          }
        }
        .image_section {
          svg {
            max-height: 291px;
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 95vh;
    main {
      width: 720px;
      .content {
        margin: 5rem 2rem;

        .text_section {
          text-align: left;
          h1 {
            max-width: 600px;
            font-size: 4rem;
            line-height: 4.5rem;
            span {
              &:after {
                content: "";
                display: none;
              }
            }
          }
          p.below {
            font-size: 1.1rem;
            margin: 0;
          }
          .ctas {
            a:last-of-type {
              margin-left: 3rem;
            }
          }
        }
        .image_section {
          svg {
          }
        }
      }
    }
    .background {
      bottom: 105px;
      left: -260px;
      right: -260px;
    }
  }

  @media (min-width: 980px) {
    height: 80vh;
    min-height: 650px;
    main {
      width: 980px;
      .content {
        flex-direction: row;
        .text_section {
          p.above {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
          }
          h1 {
            margin-top: 1.5rem;
            line-height: 4.75rem;
            span {
              &:after {
                content: " ";
                display: block;
              }
            }
          }
          .ctas {
            justify-content: flex-start;
            a:last-of-type {
              margin-left: 1rem;
            }
          }
        }
        .image_section {
          svg {
            margin-left: auto;
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    main {
      width: 72rem;
      margin: 0 auto;
      .content {
        flex-direction: row;
        .text_section {
          p.above {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
          }
          h1 {
            margin-top: 1.5rem;
            line-height: 4.75rem;
            span {
              &:after {
                content: " ";
                display: block;
              }
            }
          }
          p.below {
            margin-right: 3rem;
          }
        }
        .image_section {
          svg {
            max-width: 550px;
            max-height: 351px;
          }
        }
      }
    }
  }
`;
