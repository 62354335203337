import React from "react";
import { ReactComponent as HeroImage } from "../../../assets/hero_image.svg";
import { HeroWrapper } from "./styled.components";
import Fade from "react-reveal/Fade";
import Button from "../../../components/Button";

const Hero = ({ id }) => {
  return (
    <HeroWrapper id={id}>
      <main>
        <div className="content">
          <Fade bottom cascade duration={600}>
            <div className="text_section">
              <p className="above">Next generation platform</p>
              <h1>
                The <span>Smarter</span> way to send Transcripts
              </h1>
              <p className="below">
                Utilizing blockchain technology, we deliver unforgeable copies
                of academic records instantly at a fraction of the price.
              </p>
              <div className="ctas">
                <Button to="/auth">Verify Documents</Button>
                <Button alt to="/contact">
                  Get a demo
                </Button>
              </div>
            </div>
          </Fade>
          <div className="image_section">
            <Fade bottom cascade duration={600}>
              <HeroImage />
            </Fade>
          </div>
        </div>
      </main>
      <div className="background" />
    </HeroWrapper>
  );
};

export default Hero;
