import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import HomeContainer from "../../containers/Home";
import UploadContainer from "../../containers/Upload";
import ViewerContainer from "../../containers/Viewer";
import RecordsContainer from "../../containers/Records";
import PendingRecordsContainer from "../../containers/PendingRecords";
import { DashboardWrapper } from "./styled.components";

const NotFound = () => {
  return <Redirect to="/home" />;
};

const Dashboard = () => {
  return (
    <DashboardWrapper>
      <Router>
        <Sidebar />
        <Switch>
          <Route path="/home">
            <HomeContainer title="Home" />
          </Route>
          <Route path="/viewer">
            <ViewerContainer />
          </Route>
          <Route exact path="/upload">
            <UploadContainer title="Upload" />
          </Route>
          <Route exact path="/records">
            <RecordsContainer title="All Records" />
          </Route>
          <Route exact path="/pending-records">
            <PendingRecordsContainer title="Pending Records" />
          </Route>
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </DashboardWrapper>
  );
};

export default Dashboard;
