import styled from "styled-components";
import { Link } from "react-router-dom";

export const BtnWrapper = styled(Link)`
  position: relative;
  display: inline-block;
  margin: 0px;
  text-align: center;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 2px solid ${(props) => props.theme.colors.primary};
  outline: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 600;
  padding: 10px 30px;
  min-width: 130px;
  white-space: nowrap;
  border-radius: 50px;
  transition: all 0.2s ease-in-out 0s;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};

  :hover,
  :focus {
    color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.dark};
    background-color: ${(props) => props.theme.colors.dark};
  }
`;
export const BtnWrapperAlt = styled(Link)`
  position: relative;
  display: inline-block;
  margin: 0px;
  text-align: center;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 2px solid ${(props) => props.theme.colors.primary};
  outline: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 600;
  padding: 10px 30px;
  min-width: 130px;
  white-space: nowrap;
  border-radius: 50px;
  transition: all 0.2s ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};
  background-color: transparent;

  :hover,
  :focus {
    color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

export const BtnWrapperFunction = styled.button`
  position: relative;
  display: inline-block;
  margin: 0px;
  text-align: center;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 2px solid ${(props) => props.theme.colors.primary};
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 600;
  padding: 10px 30px;
  width: 130px;
  white-space: nowrap;
  border-radius: 50px;
  transition: all 0.2s ease-in-out 0s;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};

  :hover,
  :focus {
    outline: none;
    color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.dark};
    background-color: ${(props) => props.theme.colors.dark};
  }
`;

export const BtnWrapperFunctionAlt = styled.button`
  position: relative;
  display: inline-block;
  margin: 0px;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 2px solid ${(props) => props.theme.colors.primary};
  outline: 0px;
  font-family: "Circular", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 600;
  padding: 14px 32px;
  white-space: nowrap;
  border-radius: 8px;
  transition: 0.3s cubic-bezier(0.1, 0, 0.1, 1);
  color: ${(props) => props.theme.colors.gray[0]};
  background-color: transparent;

  :hover,
  :focus {
    box-shadow: inset 18rem 0 0 0 ${(props) => props.theme.colors.primary};
    color: #fff;
    background-color: #fff;
  }
`;
