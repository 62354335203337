import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthForm from "../../containers/AuthForm";
import { useAuth } from "../../context/AuthContext";

const NewPassword = ({ location }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [code, setCode] = useState("");
  const [mode, setMode] = useState("");
  const [codeError, setCodeError] = useState(false);
  let history = useHistory();
  const { verifiedResetCode, confirmResetPassword } = useAuth();

  useEffect(() => {
    console.log("Location: ", location);
    const params = new URLSearchParams(location.search);
    const urlMode = params.get("mode");
    const urlCode = params.get("oobCode");
    if (!urlMode) {
      setMode("unset");
    } else {
      setMode(urlMode);
    }
    if (!urlCode) {
      setCode("unset");
    } else {
      setCode(urlCode);
    }
  }, [location]);

  // Validate code
  useEffect(() => {
    if (code) {
      verifiedResetCode(code).then((r) => {
        console.log(r);
      });
    }
  }, [code]);

  const onSubmit = async (values, { setSubmitting }) => {
    setIsSuccess(false);
    setIsError(false);
    setIsMessage(false);
    setSubmitting(true);

    return await confirmResetPassword(code, values.password)
      .then((r) => {
        console.log(r);
        if (r.errorCode) {
          setIsError(r.errorCode);
        } else {
          setIsMessage("Password Changed!");
          setIsSuccess(true);
        }
      })
      .catch((err) => console.error("Error:", err));
  };

  const resetPassword = {
    title: "Reset Password",
    action: "Confirm",
    isMessage,
    isSuccess,
    isError,
    onSubmit,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password is too short")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    // eslint-disable-next-line no-undef

    fields: [
      {
        name: "password",
        label: "New Password",
        placeholder: "New Password",
        type: "password",
        initialValue: "",
      },
      {
        name: "confirmPassword",
        label: "Confirm Password",
        placeholder: "Repeat Password",
        type: "password",
        initialValue: "",
      },
    ],

    authActions: [
      { text: "Don't have an Account?", to: "/register" },
      { text: "Log In", to: "/" },
    ],
  };
  const invalidCode = {
    title: "Invalid Link",
    action: "Return to login",
    isMessage,
    isSuccess,
    isError,
    onSubmit: () => history.push("/"),
    validationSchema: "",
    // eslint-disable-next-line no-undef

    fields: [],

    authActions: [
      // { text: "Log In", to: "" }
    ],
  };

  return (
    <AuthForm
      form={
        codeError
          ? invalidCode
          : mode === "resetPassword"
          ? resetPassword
          : invalidCode
      }
    />
  );
};

export default NewPassword;
