import styled from "styled-components";

export const FeaturesWrapper = styled.div`
  position: relative;
  z-index: 1;

  main {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 2rem 1rem;
      .title {
        h1 {
          font-weight: 700;
        }
        margin-bottom: 1rem;
      }
      .boxes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .box {
          position: relative;
          width: 100%;
          max-width: 318px;
          padding: 0 3rem;
          text-align: center;
          flex: 0 0 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 70px;
          .icon {
            min-height: 80px;
            display: flex;
            justify-content: center;
            img {
              height: 60px;
            }
          }
          h2 {
            font-weight: 700;
            font-size: 1.5rem;
          }
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    main {
      .content {
        .title {
          text-align: left;
          margin-right: auto;
          h1 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
          }
        }
        .boxes {
          .box {
            text-align: left;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 1rem;

            .icon {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    main {
      width: 720px;
      .content {
        .boxes {
          .box {
            h2 {
              font-size: 1.6rem;
            }
            p {
              font-size: 1rem;
            }
            .icon {
              img {
                height: 65px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 980px) {
    main {
      width: 980px;
      .content {
        .boxes {
          .box {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    main {
      width: 72rem;
      margin: 0 auto;
      .content {
        .title {
          h1 {
            font-size: 3.5rem;
            margin-bottom: 2.4rem;
          }
        }

        .boxes {
          .box {
            .icon {
              img {
                height: 70px;
              }
            }
          }
        }
      }
    }
  }
`;
