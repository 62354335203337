import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.colors.dark};
  display: block;
  padding-top: 8rem;

  main {
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
    max-width: 72rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    svg {
      height: 100%;
      width: 130px;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 1rem;
      color: ${(props) => props.theme.colors.white}9A;
    }
    a {
      color: ${(props) => props.theme.colors.light};
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    main {
      flex-direction: row;
      p {
        margin-top: 0;
      }
    }
  }
`;
