import styled from "styled-components";

export const SidebarWrapper = styled.div`
  width: clamp(250px, 20vw, 500px);
  background-color: #005792;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: #005792;
    z-index: 2;
    img {
      height: 45px;
    }

    svg {
      width: 63%;
    }
  }

  .main_section {
    flex: 1;
    display: flex;
    width: 100%;
    max-height: calc(100vh - 80px);

    ul {
      width: 100%;
      padding: 0.6em 0 0.6em 0.5em;
      padding-left: max(1.5vw, 0.5em);
      margin-top: 0.4em;
      display: flex;
      flex-direction: column;
      li {
        list-style-type: none;
        :last-of-type {
          margin-top: auto;
        }
        a {
          position: relative;
          display: flex;
          justify-content: start;
          align-items: center;
          padding: 0.5em 1em 0.5em 0.7em;
          margin: 0.2em 0;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          font-size: 25px;
          color: rgba(255, 255, 255, 0.8);
          cursor: pointer;
          transition: transform 0.3s ease;

          span {
            padding-left: 1em;
            font-size: 16px;
          }

          :hover {
            color: #fff;
            transform: translateY(-3px);
          }
          &.active {
            background-color: #effbff;
            color: #00bbf0;
            span {
              color: #005792;
            }
            :before {
              content: "";
              width: 25px;
              height: 50px;
              position: absolute;
              background-color: #005792;
              right: 0;
              top: -50px;
              border-bottom-right-radius: 50px;
              box-shadow: 0 25px 0 0 #effbff;
            }
            :after {
              content: "";
              width: 25px;
              height: 50px;
              position: absolute;
              background-color: #005792;
              right: 0;
              bottom: -50px;
              border-top-right-radius: 50px;
              box-shadow: 0 -25px 0 0 #effbff;
            }
            :hover {
              color: #00bbf0;
              span {
                color: #005792;
              }
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
`;
