import React, { createContext, useContext, useEffect, useState } from "react";
import app from "../config/firebase";
import { getFirestore, getDoc, doc, setDoc, addDoc } from "firebase/firestore";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as logOut,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
const db = getFirestore();
const auth = getAuth();
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function signUp(values) {
    return createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        // Signed in
        delete values.password;
        const user = userCredential.user;
        console.log("Logged in:", user.uid);
        setDoc(doc(db, "users", user.uid), { ...values });

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Code:", errorCode);
        console.error("Error Message:", errorMessage);
      });
  }
  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Logged in:", user);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Code:", errorCode);
        console.error("Error Message:", errorMessage);
        return { error: true, errorCode };
      });
  }

  function signOut() {
    return logOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Signed out");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error:", error);
      });
  }

  function resetPasswordEmail(email) {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        // Sign-out successful.
        console.log("Email Sent");
        return { message: "Email Sent" };
      })
      .catch((error) => {
        // An error happened.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Code:", errorCode);
        console.error("Error Message:", errorMessage);
        return { error: true, errorCode };
      });
  }

  function verifiedResetCode(code) {
    return verifyPasswordResetCode(auth, code).catch((err) =>
      console.error("ERROR: ", err)
    );
  }

  function confirmResetPassword(code, newPassword) {
    return confirmPasswordReset(auth, code, newPassword).catch((err) =>
      console.error("ERROR: ", err)
    );
  }

  function getUser() {
    return auth.currentUser;
  }
  async function getUserInfo(uid) {
    return await getDoc(doc(db, "users", uid));
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserInfo(user.uid).then((e) => {
          setCurrentUser({ ...e.data(), ...user });
          setLoading(false);
        });
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    getUser,
    signIn,
    signOut,
    signUp,
    resetPasswordEmail,
    verifiedResetCode,
    confirmResetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
