import React from "react";
import Identicon from "identicon.js";
import { Document, Page, pdfjs} from 'react-pdf';

const Main = ({
  enteredVerifier,
  captureFile,
  uploadRecord,
  verifyRecord,
  records,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = 
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <main
          role="main"
          className="col-lg-12 ml-auto mr-auto"
          style={{ maxWidth: "500px" }}
        >
          <div className="content mr-auto ml-auto">
            <p>&nbsp;</p>

            {/* Code ... */}

            <p>&nbsp;</p>
            <h2>Submit Record</h2>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const verifier = enteredVerifier.value;
                uploadRecord(verifier);
              }}
            >
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .gif, .pdf"
                onChange={captureFile}
              />
              <div className="form-group mr-sm-2">
                <br></br>
                <input
                  id="enteredVerifier"
                  type="text"
                  ref={(input) => {
                    enteredVerifier = input;
                  }}
                  className="form-control"
                  placeholder="Verifier public key"
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block btn-lg"
              >
                Upload!
              </button>
            </form>
            <p>&nbsp;</p>

            {records.map((record, key) => {
              if (!record.hidden) {
                return (
                  <div className="card mb-4" key={key}>
                    <div className="card-header">
                      <small className="text-muted">{record.verifier}</small>
                    </div>
                    <ul id="imageList" className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p className="text-center">
                          <img
                            src={`https://ipfs.infura.io/ipfs/${record.hash}`}
                            style={{ maxWidth: "420px" }}
                          />
                          <Document file={`https://ipfs.infura.io/ipfs/${record.hash}`} >
                          <Page pageNumber={1} />
                          </Document>
                        </p>
                        <p>{record.verified ? "Verified" : "Not Verified"}</p>
                      </li>
                      <li key={key} className="list-group-item py-2">
                        <small className="float-left mt-1 text-muted">{`https://ipfs.infura.io/ipfs/${record.hash}?filename=test.pdf`}</small>
                        
                        <a href={`https://ipfs.infura.io/ipfs/${record.hash}?filename=test.pdf`}>
                        Preview
                        </a>
                        <button
                          className="btn btn-link btn-sm float-right pt-0"
                          name={record.id}
                          onClick={(event) => {
                            verifyRecord(event.target.name);
                          }}
                        >
                          Verify Record
                        </button>
                      </li>
                    </ul>
                  </div>
                );
              }
            })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Main;
