import styled from "styled-components";

export const ModalWrapper = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #595959e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    cursor: pointer;
    svg {
      height: 30px;
      width: 30px;
    }
  }
  .viewer_container {
    margin: 0 auto;
    padding: 25px;
    background-color: #5a5a5a;
    position: relative;
    transition: opacity 0.3s;
    /* max-width: Calc(100vw - 300px); */
    border-radius: 10px;
    overflow: auto;
    display: flex;
    justify-content: center;
  }
`;
