import React from "react";
import { FeaturesWrapper } from "./styled.components";
import Fade from "react-reveal/Fade";
import block from "../../../assets/icons/blockchain.png";
import cloud from "../../../assets/icons/cloud.png";
import upload from "../../../assets/icons/uploadfolder.png";
import sign from "../../../assets/icons/sign.png";
import invite from "../../../assets/icons/invite.png";
import globe from "../../../assets/icons/globe.png";
const boxes = [
  {
    icon: upload,
    title: "Easy Uploads",
    subtitle:
      "Simply upload a csv or Excel file containing the necessary information.",
  },
  {
    icon: cloud,
    title: "Add to IPFS",
    subtitle:
      "We will take your data and store it in IPFS where it is safe forever.",
  },
  {
    icon: block,
    title: "Blockchain",
    subtitle:
      "The reference to your files are added to the blockchain to provide easy access.",
  },
  {
    icon: sign,
    title: "Verifiable",
    subtitle:
      "Once added to the blockchain the issuing university is prompted to verify them.",
  },
  {
    icon: invite,
    title: "Accesible",
    subtitle: "Students are notified about the verification of their records.",
  },
  {
    icon: globe,
    title: "Universal",
    subtitle:
      "Now students can instantly distribute verified copies of their credentials.",
  },
];
const Features = ({ id }) => {
  return (
    <FeaturesWrapper id={id}>
      <main>
        <div className="content">
          <Fade cascade bottom duration={600}>
            <div className="title">
              <h1>Features</h1>
            </div>
          </Fade>
          <div className="boxes">
            <Fade bottom cascade duration={600}>
              {boxes.map((box) => {
                return (
                  <div className="box" key={box.title}>
                    <div className="icon">
                      <img src={box.icon} alt={box.title} />
                    </div>
                    <h2>{box.title}</h2>
                    <p>{box.subtitle}</p>
                  </div>
                );
              })}
            </Fade>
          </div>
        </div>
      </main>
    </FeaturesWrapper>
  );
};

export default Features;
