import React from "react";
import { CompareWrapper } from "./styled.components";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import Fade from "react-reveal/Fade";
const Compare = ({ id }) => {
  return (
    <CompareWrapper id={id}>
      <main>
        <div className="content">
          <Fade cascade bottom duration={600}>
            <div className="title">
              <h1>How we stack up</h1>
            </div>
          </Fade>
          <div className="cards">
            <Fade bottom cascade duration={600}>
              <div className="card them">
                <h2>The other guys</h2>
                <ul>
                  <li>
                    <BiXCircle /> <h4>Up to $15 per transcript</h4>
                  </li>
                  <li>
                    <BiXCircle /> <h4>5 to 7 days to transfer</h4>
                  </li>
                  <li>
                    <BiXCircle /> <h4>Requires trusted 3rd party</h4>
                  </li>
                </ul>
              </div>
              <div className="card us">
                <h2>Octavo</h2>
                <ul>
                  <li>
                    <BiCheckCircle /> <h4>Free to send everywhere</h4>
                  </li>
                  <li>
                    <BiCheckCircle /> <h4>Instantly certify</h4>
                  </li>
                  <li>
                    <BiCheckCircle /> <h4>Based on Blockchain</h4>
                  </li>
                  <li>
                    <BiCheckCircle /> <h4>Independently verifiable</h4>
                  </li>
                  <li>
                    <BiCheckCircle /> <h4>Guaranteed forever</h4>
                  </li>
                </ul>
              </div>
            </Fade>
          </div>
        </div>
      </main>
    </CompareWrapper>
  );
};

export default Compare;
