import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import Logo from "../../assets/washulogo.png";
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
Font.register({
  family: "Canterbury",
  src: "./Canterbury.ttf",
});
Font.register({
  family: "Script",
  src: "./Script.ttf",
});
const styles = StyleSheet.create({
  body: {
    flexGrow: 1,
    border: "17px #FF0000 solid",
    borderColor: "#8b0000",
    padding: "25px",
  },
  title_section: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    textAlign: "center",
    fontFamily: "Canterbury",
  },
  title: {
    fontSize: "70px",
    margin: "20px 0",
  },
  subtitle: {
    fontSize: "26px",
    margin: "0",
  },
  nomargin: {
    margin: "0",
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    textAlign: "center",
    fontFamily: "Script",
    marginTop: "25px",
    marginBottom: "0",
  },
  name: {
    fontSize: "26px",
    fontFamily: "Times-Roman",
    margin: "0",
    padding: "0",
  },
  bottom_section: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "15px",
  },
  image: {
    height: "100px",
    width: "85px",
    display: "flex",
    alignSelf: "center",
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  block: {
    flexGrow: 1,
  },
  text: {
    width: "60%",
    margin: 10,
    fontFamily: "Oswald",
    textAlign: "justify",
  },
  fill1: {
    width: "40%",
    backgroundColor: "#e14427",
  },
  fill2: {
    flexGrow: 2,
    backgroundColor: "#e6672d",
  },
  fill3: {
    flexGrow: 2,
    backgroundColor: "#e78632",
  },
  fill4: {
    flexGrow: 2,
    backgroundColor: "#e29e37",
  },
});

export default ({ name, degree }) => (
  <Document>
    <Page size="A4" orientation="landscape">
      <View style={styles.body}>
        <View style={styles.title_section}>
          <Text style={styles.subtitle}>The Corperation of</Text>
          <Text style={styles.title}>Washington University</Text>
          <Text style={styles.subtitle}>in the city of St. Louis</Text>
        </View>
        <View style={styles.info}>
          <Text style={styles.subtitle}>In The State of Missouri</Text>
          <Text style={styles.subtitle}>
            in the this text is too small to read but the line is long on the
            real one
          </Text>
        </View>
        <View style={styles.info}>
          <Text style={styles.name}>{name || "John Doe"}</Text>
          <Text style={styles.subtitle}>the degree of</Text>
          <Text style={styles.name}>{degree || "Bachelor of Science"}</Text>
        </View>
        <View style={styles.bottom_section}>
          <Image src={Logo} style={styles.image} />
        </View>
      </View>
    </Page>
  </Document>
);
