import React, { useState } from "react";
import * as Yup from "yup";
import AuthForm from "../../containers/AuthForm";
import { useAuth } from "../../context/AuthContext";
const Login = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const { signIn } = useAuth();

  const onSubmit = async (values, { setSubmitting }) => {
    setIsSuccess(false);
    setIsError(false);
    setSubmitting(true);

    return await signIn(values.email, values.password)
      .then((r) => {
        console.log(r);
        if (r?.error) {
          setIsError(r.errorCode);
        } else {
          setIsSuccess(true);
        }
      })
      .catch((err) => console.error("Error:", err));
  };

  const form = {
    title: "Log In",
    action: "Log In",
    isSuccess,
    isError,
    onSubmit,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be an email")
        .required("Required"),
      password: Yup.string().required("Required"),
    }),
    // eslint-disable-next-line no-undef

    fields: [
      {
        name: "email",
        label: "E-mail",
        placeholder: "ie. john.doe@email.com",
        type: "email",
        initialValue: "",
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        type: "password",
        initialValue: "",
      },
    ],

    authActions: [
      { text: "Forgot Password?", to: "password-reset" },
      // { text: "Join our beta", to: "../contact" },
    ],
  };

  return <AuthForm form={form} />;
};

export default Login;
