import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { HeaderWrapper, MobileMenu } from "./styled.components";
import { ReactComponent as Logo } from "../../../assets/Octavo_logo_dark.svg";
const Header = () => {
  const [menu, setMenu] = useState(false);

  const toggleMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <HeaderWrapper>
        <nav>
          <div className="left_side">
            <Logo />
            <div className="more_options">
              <div className="item"></div>
            </div>
          </div>
          <div className="right_side">
            <Link className="hide1" to="/contact">
              Get a demo
            </Link>
            <Link className="hide2" to="/auth">
              Sign in
            </Link>
            <Link className="pill" to="/contact">
              Contact us
            </Link>
            <button className={`menu ${menu}`} onClick={toggleMenu}>
              <div>
                <span className="top"></span>
                <span className="bottom"></span>
              </div>
            </button>
          </div>
        </nav>
        <MobileMenu className={`${menu}`}>
          <div className="links">
            <ul>
              <li>
                <ScrollLink
                  to="hero"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  delay={0}
                  offset={-180}
                  onClick={toggleMenu}
                >
                  Home
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="features"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-90}
                  onClick={toggleMenu}
                >
                  Features
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="about"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  delay={0}
                  offset={-90}
                  onClick={toggleMenu}
                >
                  About
                </ScrollLink>
              </li>
            </ul>
            <div className="action_btn" onClick={toggleMenu}>
              <Link className="pill" to="/contact">
                Contact us
              </Link>
            </div>
          </div>
        </MobileMenu>
      </HeaderWrapper>
      <div style={{ height: 68 }} />
    </>
  );
};

export default Header;
