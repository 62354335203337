import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  BiHomeSmile,
  BiRocket,
  BiReceipt,
  BiSearch,
  BiPulse,
} from "react-icons/bi";
import { CardWrapper } from "./styled.components";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";

// register lottie and define custom element
defineLordIconElement(loadAnimation);
const HomeCard = ({ icon, title, subtitle, link }) => {
  return (
    <CardWrapper to={link}>
      <div className="icon">
        <lord-icon
          src={icon}
          trigger="hover"
          colors="primary:#005792,secondary:#00bbf0"
          style={{ width: "150px", height: "150px" }}
        ></lord-icon>
      </div>
      <h2>{title}</h2>
      <span>{subtitle}</span>
    </CardWrapper>
  );
};

export default HomeCard;
