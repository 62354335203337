import React, { useState } from "react";
import * as Yup from "yup";
import AuthForm from "../../containers/AuthForm";
import { useAuth } from "../../context/AuthContext";

const PasswordReset = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMessage, setIsMessage] = useState(false);

  const { resetPasswordEmail } = useAuth();

  const onSubmit = (values, { setSubmitting }) => {
    setIsSuccess(false);
    setIsError(false);
    setIsMessage(false);
    setSubmitting(true);
    return resetPasswordEmail(values.email).then((r) => {
      if (r.error) {
        setIsError(r.errorCode);
      } else {
        setIsMessage("Email Sent!");
        setIsSuccess(true);
      }
    });
  };

  const form = {
    title: "Reset Password",
    action: "Reset",
    isMessage,
    isSuccess,
    isError,
    onSubmit,
    validationSchema: Yup.object({
      email: Yup.string().email("Must be an email").required("Required"),
    }),
    // eslint-disable-next-line no-undef

    fields: [
      {
        name: "email",
        label: "E-mail",
        placeholder: "ie. john.doe@email.com",
        type: "email",
        initialValue: "",
      },
    ],

    authActions: [
      // { text: "Don't have an Account?", to: "register" },
      { text: "Log In", to: "" },
    ],
  };

  return <AuthForm form={form} />;
};

export default PasswordReset;
