import React, { useState } from "react";
import {
  BiHomeSmile,
  BiRocket,
  BiReceipt,
  BiSearch,
  BiPulse,
  BiTrash,
} from "react-icons/bi";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import HomeCard from "../../components/HomeCard";
import { ContainerWrapper } from "./styled.components";

const cards = [
  {
    icon: "https://cdn.lordicon.com/nocovwne.json",
    title: "Upload",
    subtitle: "Upload student data",
    link: "/upload",
  },
  {
    icon: "https://cdn.lordicon.com/msoeawqm.json",
    title: "Search",
    subtitle: "Search student data",
    link: "/search",
  },
  {
    icon: "https://cdn.lordicon.com/yyecauzv.json",
    title: "Verify Records",
    subtitle: "Verify student records",
    link: "/pending-records",
  },
  {
    icon: "https://cdn.lordicon.com/ochimkct.json",
    title: "My Records",
    subtitle: "View all records",
    link: "/records",
  },
];
const HomeContainer = ({ title }) => {
  return (
    <ContainerWrapper>
      <header>
        <h1>{title}</h1>
      </header>
      <div className="main_section">
        {cards.map((card) => (
          <HomeCard {...card} key={card.title} />
        ))}
      </div>
    </ContainerWrapper>
  );
};

export default HomeContainer;
