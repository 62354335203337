import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { contactSubmission } from "../../functions";
import ContactContainer from "../../containers/Landing/Contact";
import Footer from "../../components/Landing/Footer";
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const onSubmit = async (values, { setSubmitting }) => {
    setIsSuccess(false);
    setIsError(false);
    setSubmitting(true);
    console.log(values);

    return await contactSubmission(values)
      .then((r) => {
        console.log(r);
        if (r?.error) {
          setIsError(r.errorCode);
        } else {
          setIsSuccess(true);
        }
      })
      .catch((err) => console.error("Error:", err));
  };

  const form = {
    title: "Contact",
    action: "Send",
    isSuccess,
    isError,
    onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Must be an email").required("Required"),
    }),
    // eslint-disable-next-line no-undef

    fields: [
      {
        name: "name",
        label: "Name",
        placeholder: "ie. John Doe",
        type: "text",
        initialValue: "",
      },
      {
        name: "email",
        label: "E-mail",
        placeholder: "ie. john.doe@college.edu",
        type: "email",
        initialValue: "",
      },
      {
        name: "university",
        label: "University",
        placeholder: "ie. Washington University",
        type: "text",
        initialValue: "",
      },

      {
        name: "message",
        label: "Message",
        placeholder: "Start typing here...",
        multiline: true,
        initialValue: "",
      },
    ],
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <ContactContainer form={form} />
      <Footer />
    </div>
  );
};

export default Contact;
