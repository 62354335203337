import styled from "styled-components";

export const HeaderWrapper = styled.header`
  z-index: 99;
  background-color: #fff;
  top: 0;
  position: fixed;
  width: 100%;
  display: block;
  height: 68px;
  nav {
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    max-width: 72rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;

    .left_side {
      height: auto;
      display: flex;
      align-items: center;
      svg {
        height: 100%;
        width: 130px;
      }
      .more_options {
        display: flex;
        align-items: center;
        align-self: stretch;
        line-height: 1.25;

        .item {
          position: relative;
          display: flex;
          align-items: center;
          align-self: stretch;
        }
      }
    }
    .right_side {
      height: auto;
      display: flex;
      align-items: center;
      align-self: stretch;

      a {
        margin-left: 1.5rem;
        color: #040404;
        fill: #040404;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.01em;
        -webkit-font-smoothing: antialiased;
        &.pill {
          color: ${(props) => props.theme.colors.white};
          fill: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.primary};
          border-radius: 100px;
          padding: 0.5rem 1rem;
          line-height: 1.25;
          transition: 0.085s all ease-in;

          :hover {
            background-color: ${(props) => props.theme.colors.dark};
          }
        }
      }
      button.menu {
        position: relative;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0 !important;
        margin-left: 1.5rem;
        background: none;
        width: 1.5rem;
        height: 2rem;
        border-color: rgba(0, 0, 0, 0);
        appearance: none;
        outline: none;
        div {
          position: relative;
          width: 1.5rem;
          height: 12px;
          transition: 0.085s all ease-in;
          cursor: pointer;
          display: flex;
          span {
            position: absolute;
            height: 2px;
            width: 1.5rem;
            -webkit-transform-origin: left;
            -ms-transform-origin: left;
            transform-origin: left;
            display: block;
            transition: 0.085s all ease-in;
            border-radius: 100px;
            background-color: #444;
            &.top {
              top: 0;
            }
            &.bottom {
              bottom: 0;
            }
          }
        }
        :hover {
          span.top {
            width: 1rem;
          }
        }

        &.true {
          div {
            width: 18px;
            height: 19px;
          }
          span.top {
            transform-origin: left center;
            width: 1.5rem;
            transform: rotate(45deg);
          }
          span.bottom {
            transform-origin: left center;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .hide1,
  .hide2 {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    .hide2 {
      display: unset;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    .hide1 {
      display: unset;
    }
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  height: calc(100vh - 68px);
  top: 68px;
  z-index: 99;
  left: -100vw;
  bottom: 0;
  width: 100vw;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out 0s;

  .links {
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin-top: -5rem;

    ul {
      padding: 0px;
      margin: 0px auto;
      list-style: none;
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      li {
        padding: 0px;
        margin: 0px auto;
        list-style: none;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        a {
          display: block;
          font-size: 17px;
          line-height: 1.6;
          font-weight: 600;
          color: ${(props) => props.theme.colors.gray[0]};
          transition: all 0.2s ease-in-out 0s;
          margin: 16px 0px;
          cursor: pointer;

          &.active {
            color: ${(props) => props.theme.colors.primary};
          }
        }
      }
    }

    .action_btn {
      display: flex;
      justify-content: center;
      margin-top: 2em;

      a {
        position: relative;
        display: inline-block;
        margin: 0px;
        font-style: inherit;
        font-variant: inherit;
        font-stretch: inherit;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        border: none;
        outline: 0px;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 600;
        padding: 0.75rem 2rem;
        white-space: nowrap;
        border-radius: 200px;
        transition: all 0.2s ease-in-out 0s;
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};
        box-shadow: rgb(24 102 180 / 16%) 0px 24px 48px;

        :hover {
          background-color: ${(props) => props.theme.colors.dark};
        }
      }
    }
  }

  &.true {
    display: flex;
    right: 0;
    left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
  }
  /* @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    &.active {
      display: none;
    }
  } */
`;
