import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { AuthWrapper } from "./styled.components";
import Login from "./login";
import Register from "./register";
import ForgotPassword from "./password-reset";
import NewPassword from "./new-password";

const NotFound = () => {
  return <Redirect to="/" />;
};

const index = () => {
  return (
    <AuthWrapper>
      <Link to="/" className="back_btn">
        <BsArrowLeft />
      </Link>
      <Router basename="/auth">
        <Switch>
          <Route exact path="/password-reset" component={ForgotPassword} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/" component={Login} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </AuthWrapper>
  );
};

export default index;
