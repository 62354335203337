import React from "react";
import { ModalWrapper } from "./styled.components";
import { BlobProvider } from "@react-pdf/renderer";
import { Document, Page, pdfjs } from "react-pdf";
import { BiX } from "react-icons/bi";
import MyDoc from "./test";
const DiplomaModalContainer = ({ name, degree, toggleModal }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <ModalWrapper>
      <div className="close_btn" onClick={() => toggleModal({ open: false })}>
        <BiX />
      </div>
      <div className="viewer_container">
        <BlobProvider document={<MyDoc name={name} degree={degree} />}>
          {({ blob, url, loading, error }) => {
            // Do whatever you need with blob here
            return (
              <Document file={blob}>
                <Page pageNumber={1} />
              </Document>
            );
          }}
        </BlobProvider>
      </div>
    </ModalWrapper>
  );
};

export default DiplomaModalContainer;
