import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardWrapper = styled(Link)`
  flex: 1;
  display: flex;
  margin: 1em;
  padding: 1em;
  min-width: 300px;
  width: clamp(350px, 30vw, 500px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 7px;
  border-top: 4px solid #00bbf0;
  cursor: pointer;
  transition: all 300ms ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transform: translateY(-6px);
  }

  .icon {
    padding: 0.3em;
  }

  span {
    font-size: 1em;
    color: #00204a;
  }
`;
