import styled from "styled-components";

export const DashboardWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  min-height: 100vh;
  display: flex;
  font-family: "Roboto", sans-serif;
  /* :before {
    content: "";
    height: 50px;
    background-color: #0077b6;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
  } */

  .content_container {
    flex: 1;
    background-color: #effbff;
    display: flex;
    flex-direction: column;

    header {
      display: flex;
      align-items: center;
      height: 80px;
      padding: 0 2em;

      h1 {
        font-size: 1.7em;
        margin-bottom: 0;
      }
    }

    .main_section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .table_container {
        padding: 25px;
        background-color: #fff;
        position: relative;
        transition: opacity 0.3s;
        max-width: Calc(100vw - 300px);
        border-radius: 10px;
      }
    }
  }
`;

export const TableWrapper = styled.div`
  .ant-pagination-prev,
  .ant-pagination-next {
    line-height: 28px !important;
  }
  .ant-table table {
    text-align: left;
  }
  .ant-table-thead > tr > th {
    text-align: left;
  }

  &.table-order,
  &.table-seller,
  &.table-data-view {
    .ant-table-selection {
      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          background: #0077b6;
          border-color: #0077b6;
          &:after {
            height: 2px;
            background-color: #fff;
          }
        }
      }
    }
    .ant-table-container {
      padding-bottom: 25px;
      border-bottom: 1px solid rgb(241, 242, 246);
    }
    tbody {
      tr {
        &:hover {
          td {
            background: #f8f9fb;
          }
        }
        td {
          .product-id {
            max-width: 60px;
            text-align: right;
          }
        }
      }
    }
    .ant-pagination {
      margin-top: 25px !important;
    }
  }
  &.table-data-view {
    .ant-table-container {
      padding-bottom: 0;
    }
    table {
      thead {
        th {
          padding: 15px 16px;
        }
      }
      tbody {
        td {
          padding: 11px 16px;
          .record-img {
            img {
              max-width: 38px;
              border-radius: 50%;

              margin-right: 12px;
            }
          }
          .record-location {
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: rgb(173, 180, 210);
          }
          .status {
            font-weight: 500;
            text-transform: capitalize;
            &.active {
              color: #20c997;
              background: #20c99710;
            }
            &.deactivated {
              color: #20c997;
              background: #20c99710;
            }
            &.blocked {
              color: #f5222d;
              background: #f5222d10;
            }
            &.accepted {
              color: #0077b6;
              background: #0077b610;
            }
            &.pending {
              color: #20c997;
              background: #20c99710;
            }
            &.approved {
              color: #20c997;
              background: #20c99710;
            }
            &.rejected {
              color: #f5222d;
              background: #f5222d10;
            }
          }
          .table-actions {
            a {
              svg,
              i {
                width: 16px;
                color: rgb(241, 242, 246);
              }
              &.edit {
                margin-right: 6px;
                &:hover {
                  svg,
                  i {
                    color: #0077b6;
                  }
                }
              }
              &.delete {
                &:hover {
                  svg,
                  i {
                    color: #f5222d;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  table {
    thead {
      tr {
        border-radius: 10px;
        th {
          &:last-child {
            text-align: right;
          }
          color: #5a5f7d;
          background: #f8f9fb;
          border-top: 1px solid rgb(241, 242, 246);
          border-bottom: 1px solid rgb(241, 242, 246);
          &:first-child {
            border-left: 1px solid rgb(241, 242, 246);
            border-radius: 10px 0 0 10px !important;
          }
          &:last-child {
            border-right: 1px solid rgb(241, 242, 246);
            border-radius: 0 10px 10px 0 !important;
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            background: #f8f9fb;
          }
        }
        &.ant-table-row-selected {
          &:hover {
            td {
              background: #f8f9fb;
            }
          }
          td {
            background: #f8f9fb;
          }
        }
        td {
          border: 0 none;
          font-weight: 500;
          color: #5a5f7d;
          &:first-child {
            border-radius: 10px 0 0 10px !important;
          }
          &:last-child {
            border-radius: 0 10px 10px 0 !important;
          }
          span {
            display: block;
          }
          .order-id {
            min-width: 128px;
          }
          .customer-name {
            min-width: 174px;
          }
          .status {
            min-width: 175px;
          }
          .ordered-amount {
            min-width: 175px;
          }
          .ordered-date {
            min-width: 165px;
          }
          .table-actions {
            min-width: 60px;
          }
        }
      }
    }
    .table-actions {
      text-align: right;
      min-width: 150px !important;
      button {
        height: 40px;
        padding: 0 11px;
        background: transparent;
        border: 0 none;
        color: rgb(241, 242, 246);
        &:hover {
          &.ant-btn-primary {
            color: #0077b6;
            background: #0077b610;
          }
          &.ant-btn-info {
            color: #0077b6;
            background: #0077b610;
          }
          &.ant-btn-danger {
            color: #f5222d;
            background: #f5222d10;
          }
        }
      }
    }
    .seller-info {
      img {
        margin-right: 12px;
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      figure {
        margin: 0 8px 0;
      }
      .user-name {
        margin-bottom: 4px;
        font-weight: 500;
      }
      .user-designation {
        font-size: 12px;
        font-weight: 400;
        color: rgb(241, 242, 246);
      }
    }
  }
`;
