import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getData, updateVerified } from "../../functions";
import { Table, Popover } from "antd";
import "antd/dist/antd.css";
import { Contract, ethers } from "ethers";
import MetaMaskOnboarding from "@metamask/onboarding";
import detectEthereumProvider from "@metamask/detect-provider";
import Ucert from "../../abis/Ucert.json";
import { useAuth } from "../../context/AuthContext";
import { HiOutlineTrash, HiOutlineCheck } from "react-icons/hi";
import { FiEye } from "react-icons/fi";
import { ContainerWrapper, TableWrapper } from "./styled.components";

const RecordContainer = ({ title }) => {
  const { currentUser } = useAuth();
  const [accounts, setAccounts] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const onboarding = useRef();
  const [state, setState] = useState({
    provider: "undefined",
    account: "",
    selectedFile: null,
    ipfsHash: "",
    readyToMint: false,
    transactionHash: "",
  });
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);
  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        onboarding.current.stopOnboarding();

        // save account to parent component
        saveAccount(accounts[0]);
      }
    }
  }, [accounts]);
  const saveAccount = (account) => {
    setState((state) => ({ ...state, account: account }));
  };

  useEffect(() => {
    async function anonLoad() {
      loadProvider();
    }
    anonLoad();
  }, []);

  const loadProvider = async () => {
    const metaMaskProvider = await detectEthereumProvider();
    if (metaMaskProvider) {
      const provider = new ethers.providers.Web3Provider(metaMaskProvider);
      setState((state) => ({ ...state, provider: provider }));
    } else {
      alert("MetaMask is NOT installed!");
    }
  };
  const connectMM = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      console.log("HERE");
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };

  const [data, setData] = useState([]);

  const sendTxn = async (hash) => {
    console.log("Sending Txn");
    setLoading(true);
    const signer = state.provider.getSigner();
    const contract = new Contract(
      "0x59ED7E7189d23952eB4DF847835DCf774a038d81",
      Ucert.abi,
      signer
    );
    const txnHash = await contract.uploadRecord(
      hash,
      "0x528Fd5A4D5b0C1174922eAb91E366396623D342B"
    );

    updateVerified(currentUser.uid, hash, txnHash.hash);

    setState((state) => ({ ...state, transactionHash: txnHash.hash }));

    setLoading(false);
    setSuccess(true);

    // TODO: display transaction hash on site
    console.log(txnHash.hash);
  };

  function formatDate(time) {
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(time);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(time);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(time);

    return `${mo}/${da}/${ye}`;
  }

  const handleApprove = async (hash) => {
    await connectMM();
    await sendTxn(hash);
  };

  const columns = [
    {
      title: "Upload Date",
      dataIndex: "timeStamp",
      key: "timeStamp",
      width: "35px",
      render: (time) => formatDate(time),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      width: "20px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "30px",
      render: (st) => <span className={`status ${st}`}>{st}</span>,
    },
    {
      title: "IPFS Hash",
      dataIndex: "ipfsHash",
      key: "ipfsHash",
      width: "70px",
      render: (text) => <p className="single_line">{text}</p>,
    },
    {
      title: "Actions",
      dataIndex: "ipfsHash",
      key: "actions",
      width: "25px",
      fixed: "right",
      render: (id) => (
        <div className="actions">
          <Popover content="Approve records">
            <div className="icon view" onClick={() => handleApprove(id)}>
              <HiOutlineCheck />
            </div>
          </Popover>
          <Popover content="View records">
            <div className="icon view">
              <FiEye />
            </div>
          </Popover>
        </div>
      ),
    },
  ];

  const fetch = async () => {
    await getData(currentUser.uid, setData);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <ContainerWrapper>
      <header>
        <h1>{title || "Pending Records"}</h1>
      </header>
      <div className="main_section">
        <div className="table_container">
          <TableWrapper className="table-data-view table-responsive">
            <Table
              pagination={{ pageSize: 10, showSizeChanger: true }}
              dataSource={data}
              columns={columns}
              scroll={{ x: "calc(900px - 5%)", y: "50vh" }}
            />
          </TableWrapper>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default RecordContainer;
