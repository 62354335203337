import React from "react";
import Header from "../../components/Landing/Header";
import Hero from "../../containers/Landing/Hero";
import Features from "../../containers/Landing/Features";
import Compare from "../../containers/Landing/Compare";
import Cta from "../../containers/Landing/Cta";
import Footer from "../../components/Landing/FooterCta";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero id="hero" />
      <Features id="features" />
      <Compare id="about" />
      <Cta />
      <Footer />
    </div>
  );
};

export default Home;
