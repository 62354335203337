import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import Home from "./home";
import Contact from "./contact";

const NotFound = () => {
  return <Redirect to="/" />;
};

const index = () => {
  return (
    <Switch>
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/" component={Home} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default index;
