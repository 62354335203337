import styled from "styled-components";

export const ContainerWrapper = styled.div`
  flex: 1;
  background-color: #effbff;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 3em;
    justify-content: space-between;

    h1 {
      font-size: 2.1em;
      margin-bottom: 0;
      color: #00204a;
    }

    .download_btn {
      position: relative;
      display: inline-block;
      margin: 0px;
      text-align: center;
      font-style: inherit;
      font-variant: inherit;
      font-stretch: inherit;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
      border: 2px solid ${(props) => props.theme.colors.primary};
      outline: 0px;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      line-height: 1.6;
      font-weight: 600;
      padding: 10px 30px;
      min-width: 130px;
      white-space: nowrap;
      border-radius: 50px;
      transition: all 0.2s ease-in-out 0s;
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.primary};

      :hover,
      :focus {
        color: ${(props) => props.theme.colors.white};
        border: 2px solid ${(props) => props.theme.colors.dark};
        background-color: ${(props) => props.theme.colors.dark};
      }
    }
  }

  .main_section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 2em;

    .viewer_container {
      padding: 25px;
      background-color: #5a5a5a;
      position: relative;
      transition: opacity 0.3s;
      /* max-width: Calc(100vw - 300px); */
      border-radius: 10px;
      overflow: auto;
    }
  }
`;
