import styled from "styled-components";

export const ContactWrapper = styled.div`
  position: relative;
  z-index: 1;

  main {
    width: 100%;
    max-width: 72rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .back_btn {
      margin-right: auto;
      margin-left: 1rem;
      padding: 0.7rem;
      border-radius: 100px;
      transition: ease all 300ms;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px #ffffff00 solid;
      cursor: pointer;
      color: ${(props) => props.theme.colors.dark};

      svg {
        width: 25px;
        height: 25px;
      }

      :hover {
        border: 2px ${(props) => props.theme.colors.primary} solid;
        color: ${(props) => props.theme.colors.primary};
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 1rem 1rem 2rem;
      padding: 0 2rem;
      position: relative;

      .text_section {
        margin-bottom: 1rem;
        h1 {
          font-weight: 700;
        }
        p {
          font-size: 1rem;
        }
        .hide {
          display: none;
        }
      }
      .form_section {
        width: 100%;
        input,
        textarea {
          font-size: 16px;
        }

        p.error {
          margin: 0.5rem 0 0;
          font-size: 1rem;
          color: #e1544b;
        }
        p.message {
          margin: 0.5rem 0 0;
          font-size: 1rem;
          color: #0bb793;
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    main {
      .content {
        .title {
          text-align: left;
          margin-right: auto;
          h1 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    main {
      .content {
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;

        .text_section {
          margin-top: 2rem;
          flex: 1;
          .hide {
            display: block;
            margin-top: 2rem;
            img {
              width: 85%;
              max-width: 300px;
            }
          }
        }
        .form_section {
          flex: 1;
          margin-top: 9vh;
        }
      }
    }
  }

  @media (min-width: 980px) {
    main {
      width: 980px;
      .content {
        .cards {
          flex-direction: row;

          .card {
            &.them {
              margin-right: 3rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    main {
      width: 72rem;
      .content {
        .title {
          h1 {
            font-size: 3.5rem;
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }
`;
