import styled from "styled-components";

export const AuthWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  font-family: "Aeonik", sans-serif;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  .back_btn {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0.7rem;
    border-radius: 100px;
    transition: ease all 300ms;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px #ffffff00 solid;
    cursor: pointer;
    color: ${(props) => props.theme.colors.dark};

    svg {
      width: 25px;
      height: 25px;
    }

    :hover {
      border: 2px #ffffff solid;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;
