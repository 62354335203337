import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthForm from "../../containers/AuthForm";
import { useAuth } from "../../context/AuthContext";
const Register = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  let history = useHistory();
  const { signUp } = useAuth();

  const onSubmit = async (values, { setSubmitting }) => {
    setIsSuccess(false);
    setIsError(false);
    setSubmitting(true);

    return await signUp(values)
      .then((r) => {
        console.log(r);

        if (r?.errorCode) {
          setIsError(r.errorCode);
        } else {
          setIsSuccess(true);
        }
      })
      .catch((err) => console.error("Error:", err));
  };

  const form = {
    title: "Sign Up",
    action: "Sign Up",
    isSuccess,
    isError,
    onSubmit,
    validationSchema: Yup.object({
      university: Yup.string().required("Required"),
      publicKey: Yup.string()
        .length(42, "Public key must be 42 characters long")
        .required("Required"),
      email: Yup.string().email("Must be an email").required("Required"),
      password: Yup.string().required("Required"),
    }),
    // eslint-disable-next-line no-undef

    fields: [
      {
        name: "university",
        label: "University",
        placeholder: "ie. Washington University",
        type: "text",
        initialValue: "",
      },
      {
        name: "publicKey",
        label: "Public Key",
        placeholder: "ie. 0x000000",
        type: "text",
        initialValue: "",
      },
      {
        name: "email",
        label: "E-mail",
        placeholder: "ie. john.doe@email.com",
        type: "email",
        initialValue: "",
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        type: "password",
        initialValue: "",
      },
    ],

    authActions: [{ text: "Already have an Account?", to: " " }],
  };

  return <AuthForm form={form} />;
};

export default Register;
