import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ContainerWrapper } from "./styled.components";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { Document, Page, pdfjs } from "react-pdf";
import MyDoc from "./test";
import Modal from "../DiplomaModal";
const ViewerContainer = ({ title }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <ContainerWrapper>
      <header>
        <h1>{title || "Viewer"}</h1>

        <PDFDownloadLink
          document={<MyDoc />}
          fileName="diploma"
          className="download_btn"
        >
          Download
        </PDFDownloadLink>
      </header>
      <div className="main_section">
        <div className="viewer_container">
          <BlobProvider document={<MyDoc />} fileName="somename.pdf">
            {({ blob, url, loading, error }) => {
              // Do whatever you need with blob here
              return (
                <Document file={blob}>
                  <Page pageNumber={1} />
                </Document>
              );
            }}
          </BlobProvider>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default ViewerContainer;
