import React from "react";
import { CtaWrapper } from "./styled.components";
import Button from "../../../components/Button";
import { BiChevronRight } from "react-icons/bi";
import Fade from "react-reveal/Fade";
const Cta = () => {
  return (
    <CtaWrapper>
      <Fade bottom cascade duration={600}>
        <main>
          <h1>Heard enough? Lets get started.</h1>
          <Button to="/contact">
            Book a Demo <BiChevronRight />
          </Button>
        </main>
      </Fade>
    </CtaWrapper>
  );
};

export default Cta;
