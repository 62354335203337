import React, { useState, useEffect } from "react";
import Web3 from "web3";
import Identicon from "identicon.js";
import "./App.css";
import Ucert from "../abis/Ucert.json";
import Navbar from "./Navbar";
import Main from "./Main";

//Declare IPFS
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // leaving out the arguments will default to these values

const App = () => {
  const [state, setState] = useState({
    account: "",
    ucert: null,
    records: [],
    loading: true,
    enteredVerifier: "",
  });
  const [transactionHash, setTransactionHash] = useState("");

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;

    const accounts = await web3.eth.getAccounts();
    setState((state) => ({ ...state, account: accounts[0] }));
    //Network ID
    const networkId = await web3.eth.net.getId();
    const networkData = Ucert.networks[networkId];
    if (networkData) {
      const ucert = web3.eth.Contract(Ucert.abi, networkData.address);
      setState((state) => ({ ...state, ucert }));
      const recordsCount = await ucert.methods.recordCount().call();
      setState((state) => ({ ...state, recordsCount, loading: false }));

      //Load records
      const recordArray = [];
      for (var i = 1; i <= recordsCount; i++) {
        const record = await ucert.methods.records(i).call();
        recordArray.push(record);
      }

      setState((state) => ({
        ...state,
        records: recordArray,
      }));
    } else {
      window.alert("Contract has not been deployed to the current network");
    }
  };

  const captureFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);

    reader.onloadend = () => {
      setState((state) => ({ ...state, buffer: Buffer(reader.result) }));
      console.log("buffer", Buffer(reader.result));
    };
  };

  const uploadRecord = (verifier) => {
    console.log("Submitting file to ipfs...");
    //adding file to the IPFS

    ipfs.add(state.buffer, (error, result) => {
      console.log("Ipfs result", result);

      if (error) {
        console.error(error);
        return;
      }

      setState((state) => ({ ...state, loading: true }));
      console.log("State", state);

      state.ucert.methods
        .uploadRecord(result[0].hash, verifier)
        .send({ from: state.account })
        .on("transactionHash", (hash) => {
          console.log("TXN HASH:", hash);
          setTransactionHash(hash);
          setState((state) => ({ ...state, loading: false }));
        });
    });
  };

  const verifyRecord = (id) => {
    setState((state) => ({ ...state, loading: true }));
    state.ucert.methods
      .verifyRecord(id)
      .send({ from: state.account })
      .on("transactionHash", (hash) => {
        setState((state) => ({ ...state, loading: false }));
      });
  };
  const hideRecord = (id) => {
    setState((state) => ({ ...state, loading: true }));
    state.ucert.methods
      .hideRecord(id)
      .send({ from: state.account })
      .on("transactionHash", (hash) => {
        setState((state) => ({ ...state, loading: false }));
      });
  };

  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    loadWeb3();
    loadBlockchainData();
  };

  return (
    <div>
      <Navbar {...state} />
      {state.loading ? (
        <div id="loader" className="text-center mt-5">
          <p>Loading...</p>
        </div>
      ) : (
        <Main
          {...state}
          captureFile={captureFile}
          uploadRecord={uploadRecord}
          verifyRecord={verifyRecord}
          hideRecord={hideRecord}
        />
      )}
    </div>
  );
};

export default App;
