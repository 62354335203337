import styled from "styled-components";

export const CtaWrapper = styled.div`
  box-shadow: rgb(46 209 240 / 40%) 0px 5px, rgb(46 209 240 / 30%) 0px 10px,
    rgb(46 209 240 / 20%) 0px 15px, rgb(46 209 240 / 10%) 0px 20px,
    rgb(46 209 240 / 5%) 0px 25px;
  border-radius: 25px;
  margin: 0 1rem -7rem;
  max-width: 62rem;
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  /* background: ${(props) => props.theme.colors.secondary}; */
  background-color: #0092c7;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  main {
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      text-align: center;
      background-image: linear-gradient(
        183.3deg,
        rgba(255, 255, 255, 0.94) 6.19%,
        #76fbff 137.93%
      );
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      line-height: 3.6rem;
      font-size: 2.9rem;
      font-weight: 700;
    }
    a {
      margin-top: 1.5rem;
      background: ${(props) => props.theme.colors.secondary};
      span {
        display: flex;
        align-items: center;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    main {
      h1 {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    main {
      width: 100%;
      h1 {
        font-size: 3.6rem;
        overflow: visible;
      }
    }
  }

  @media (min-width: 980px) {
    margin: 0 auto -7rem;
    main {
      width: 100%;
      .content {
        .cards {
          flex-direction: row;

          .card {
            &.them {
              margin-right: 3rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    main {
      width: 100%;
      margin: 0 auto;
      .content {
        .title {
          h1 {
            font-size: 3.5rem;
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }
`;
