import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FooterWrapper } from "./styled.components";
import { ReactComponent as Logo } from "../../../assets/Octavo_logo.svg";
const Footer = () => {
  return (
    <FooterWrapper>
      <main>
        <div className="logo">
          <Logo />
        </div>
        <p>
          © {new Date().getFullYear()}, Built by &nbsp;
          <a href="#">Octavo</a>
        </p>
      </main>
    </FooterWrapper>
  );
};

export default Footer;
