import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Octavo_logo.svg";
import {
  BiHomeSmile,
  BiCloudUpload,
  BiArchive,
  BiSearch,
  BiBook,
  BiLogOutCircle,
} from "react-icons/bi";
import { SidebarWrapper } from "./styled.components";
import { useAuth } from "../../context/AuthContext";
const Sidebar = () => {
  const { signOut } = useAuth();
  return (
    <SidebarWrapper>
      <div className="logo">
        <Logo />
      </div>
      <div className="main_section">
        <ul>
          <li>
            <NavLink to="/home" activeClassName="active">
              <BiHomeSmile />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/records" activeClassName="active">
              <BiBook />
              <span>All Records</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/viewer" activeClassName="active">
              <BiSearch />
              <span>Search</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/pending-records" activeClassName="active">
              <BiArchive />
              <span>Pending Records</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/upload" activeClassName="active">
              <BiCloudUpload />
              <span>Upload</span>
            </NavLink>
          </li>
          <li onClick={signOut}>
            <a to="#">
              <BiLogOutCircle />
              <span>Log Out</span>
            </a>
          </li>
        </ul>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
