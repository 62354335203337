import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { uploadData } from "../../functions";
import { Upload, message, Table, Popover } from "antd";
import "antd/dist/antd.css";
import { useAuth } from "../../context/AuthContext";
import Button from "../../components/Button";
import { HiOutlineTrash } from "react-icons/hi";
import { FiEye } from "react-icons/fi";
import { ContainerWrapper, TableWrapper } from "./styled.components";
import UploadIcon from "../../assets/icons/upload.png";
import DiplomaModalContainer from "../DiplomaModal";

const { Dragger } = Upload;
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
});
const UploadContainer = ({ title }) => {
  const { currentUser } = useAuth();
  let history = useHistory();

  const [inputData, setInputData] = useState([]);
  const [ipfsHash, setIpfsHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalProps, setModalProps] = useState({
    name: "",
    degree: "",
    open: false,
  });

  const toggleModal = (props) => {
    setModalProps({ ...props });
  };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
      width: "30px",
    },
    {
      title: "Student ID",
      dataIndex: "id",
      key: "id",
      width: "20px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "35px",
    },
    {
      title: "Major",
      dataIndex: "major",
      key: "major",
      width: "35px",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      width: "20px",
      fixed: "right",
      render: (id, record) => (
        <div className="actions">
          <Popover content="View record">
            <div
              className="icon view"
              onClick={() =>
                toggleModal({
                  open: true,
                  name: record?.name,
                  degree: `Bachelors of ${record?.major}`,
                })
              }
            >
              <FiEye />
            </div>
          </Popover>
          <Popover content="Remove record">
            <div className="icon trash" onClick={() => removeRow(id)}>
              <HiOutlineTrash />
            </div>
          </Popover>
        </div>
      ),
    },
  ];

  function removeRow(id) {
    console.log("ID: ", id);
    setInputData((inputData) => inputData.filter((row) => row.id !== id));
  }
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setInputData(list);
  };

  const captureFile = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 500);
    const reader = new FileReader();
    reader.onloadend = (event) => {
      const bstr = event.target.result;
      console.log(bstr);
      const wb = XLSX.read(bstr, {
        type: "binary",
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, {
        header: 1,
      });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  //Add JSON to IPFS
  const uploadJSON = async () => {
    return await ipfs
      .add(Buffer(JSON.stringify(inputData)))
      .then((e) => {
        console.log("files sent to IPFS", e[0].hash);
        setIpfsHash(e[0].hash);
        return e[0].hash;
      })
      .catch((err) => console.error(err));
  };

  const handleSave = () => {
    //inputData is an array that needs to be pushed to ipfs and firebase
    //the ipfs hash will ve the firestore document name
    //step 1 Save to Ipfs
    setLoading(true);
    uploadJSON()
      .then((res) => uploadData(currentUser.uid, res, inputData))
      .then(() => history.push("/pending-records"))
      .catch((err) => console.error(err));
  };

  const props = {
    name: "file",
    accept: ".csv, .xlsx, .xls",
    multiple: false,
    method: "none",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <ContainerWrapper>
      <header>
        <h1>{title || "Upload"}</h1>
        {inputData.length ? (
          <Button fun onClick={handleSave}>
            {loading ? "Loading..." : "Save"}
          </Button>
        ) : null}
      </header>
      <div className="main_section">
        {!inputData.length ? (
          <Dragger {...props} customRequest={captureFile}>
            <p className="ant-upload-drag-icon">
              <img src={UploadIcon} width={100} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              We support .csv, .xlsx, and .xls file types. Please ensure the
              file is structured correctly according to our{" "}
              <Link>guidelines</Link>
            </p>
          </Dragger>
        ) : (
          <div className="table_container">
            <TableWrapper className="table-data-view table-responsive">
              <Table
                pagination={{ pageSize: 10, showSizeChanger: true }}
                dataSource={inputData}
                columns={columns}
                scroll={{ x: "calc(800px - 5%)", y: "50vh" }}
              />
            </TableWrapper>
          </div>
        )}
      </div>
      {modalProps?.open ? (
        <DiplomaModalContainer {...modalProps} toggleModal={toggleModal} />
      ) : null}
    </ContainerWrapper>
  );
};

export default UploadContainer;
