import React from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";

import Input from "../../../components/Formik/Input";
import Submit from "../../../components/Formik/Submit";
import { ContactWrapper } from "./styled.components";
import { BsArrowLeft } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import image from "../../../assets/contact.png";

const Contact = ({ form }) => {
  return (
    <ContactWrapper>
      <main>
        <Link to="/" className="back_btn">
          <BsArrowLeft />
        </Link>
        <Fade cascade bottom duration={600}>
          <div className="content">
            <div className="text_section">
              <h1>Get in touch</h1>
              <p>We will try our best to respond within 24 to 48 hours. </p>

              <div className="hide">
                <img src={image} alt="contact us" />
              </div>
            </div>
            <div className="form_section">
              <Formik
                validationSchema={form.validationSchema}
                onSubmit={form.onSubmit}
                hasSuccessStatus={form.isSuccess}
                hasErrorStatus={form.isError}
                initialValues={form.fields.reduce(
                  (acc, field) => ({
                    ...acc,
                    [field.name]: field.initialValue,
                  }),
                  {}
                )}
              >
                <Form>
                  <Fade cascade bottom duration={600}>
                    <div className="form_items">
                      {form.fields.map((field) => (
                        <Input
                          key={field.name}
                          style={{ ...field?.styles }}
                          {...field}
                        />
                      ))}
                      {form.isError && (
                        <div>
                          <p className="error">{form.isError}</p>
                        </div>
                      )}
                      {form?.isSuccess && (
                        <div>
                          <p className="message">Message sent!</p>
                        </div>
                      )}
                      <Submit type="submit">{form.action}</Submit>
                    </div>
                  </Fade>
                </Form>
              </Formik>
            </div>
          </div>
        </Fade>
      </main>
    </ContactWrapper>
  );
};

export default Contact;
