import React from "react";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "styled-components";
import { useAuth } from "./context/AuthContext";
import theme from "./theme";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Landing from "./pages/Landing";
import Auth from "./pages/AuthPages";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import Test from "./components/App";

const ProviderConfig = () => {
  const { currentUser } = useAuth();
  return (
    <Router>
      {!currentUser ? (
        <Switch>
          <Route path="/test" component={Test} />
          <Route path="/auth" component={Auth} />
          <Route path="/" component={Landing} />
        </Switch>
      ) : (
        <Route path="/" component={Dashboard} />
      )}
    </Router>
  );
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ProviderConfig />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
