import styled from "styled-components";

export const CompareWrapper = styled.div`
  position: relative;
  z-index: 1;

  main {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 2rem 1rem;
      .title {
        h1 {
          font-weight: 700;
        }
        margin-bottom: 1rem;
      }
      .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .card {
          position: relative;
          min-width: 0;
          word-wrap: break-word;
          background: #fff;
          border-radius: 10px;
          width: 90%;
          max-width: 450px;
          margin-bottom: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.125);
          h2 {
            font-weight: 700;
            padding: 2rem;
            margin-bottom: 0;
          }
          ul {
            margin: 0 0 1.5rem;
            padding: 0;
            width: 100%;
            li {
              list-style-type: none;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              border-bottom: 1px solid rgba(0, 0, 0, 0.125);
              padding: 0.75rem 1rem;

              svg {
                width: 20px;
                height: 20px;
              }
              h4 {
                font-size: 1rem;
                margin-bottom: 0;
                margin-left: 1rem;
                line-height: 1.4rem;
              }
            }
          }
          &.them {
            svg {
              color: #ff0000;
            }
          }
          &.us {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            h2 {
              color: ${(props) => props.theme.colors.primary};
              font-size: 2.5rem;
              line-height: 0.95;
            }
            svg {
              color: #2dc653;
            }
          }
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    main {
      .content {
        .title {
          text-align: left;
          margin-right: auto;
          h1 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    main {
      width: 720px;
      .content {
        .cards {
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          .card {
            &.them {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }

  @media (min-width: 980px) {
    main {
      width: 980px;
      .content {
        .cards {
          flex-direction: row;

          .card {
            &.them {
              margin-right: 3rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    main {
      width: 72rem;
      margin: 0 auto;
      .content {
        .title {
          h1 {
            font-size: 3.5rem;
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }
`;
