import styled from "styled-components";

export const ContainerWrapper = styled.div`
  flex: 1;
  background-color: #effbff;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 3em;

    h1 {
      font-size: 2.1em;
      margin-bottom: 0;
      color: #00204a;
    }
  }

  .main_section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 2em;
  }
`;
